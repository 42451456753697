.website .table tbody tr:hover {
    background-color: transparent !important;
}

.title_container h2 a {
    text-decoration: none !important;
    color: var(--ama-color-brand-primary)
}

.title_container h2 a:hover {
    text-decoration: underline !important;
}

.radar_graph,
.radar_graph_table {
    height: 500px !important;
}

.radar_graph_table {
    overflow-y: auto;
}

.good_bad ul {
    margin-bottom: 0px !important;
    background-color: transparent !important;
}

.good_bad .ama.tabs-container .nav-tabs .nav-link.active {
    background-color: transparent !important;
}

.BarLine_section ul li {
    line-height: 24px;
}

.good_bad ul li {
    font-weight: 700;
    padding: 0px;
    font-size: 22px;
    line-height: 24px;
}

.barLineContainer {
    width: 100%;
    height: 500px;
    overflow-x: auto;
}

.best_practises .table {
    margin-bottom: 25px;
}

.best_practises .light_tables .sorting_table thead tr {
    background-color: var(--ama-color-background-light-lighter) !important;
}

.best_practises .light_tables .sorting_table thead tr th {
    color: var(--ama-color-grey-900) !important;
}

.best_practises .light_tables .sorting_table tbody tr td:last-child {
    width: 200px !important;
}

.topTen_section ul {
    background-color: var(--ama-color-background-light-darker) !important;
    margin-bottom: 10px !important;
}

.topTen_section .ama.tabs-container .nav-tabs .nav-link.active {
    background-color: var(--ama-color-background-light-darker) !important;
}

.topTen_section ul li {
    font-size: 16px;
}

mark {
    background-color: initial;
    font-weight: 900 !important;
    text-decoration: underline;
}

/* MOBILE */
@media (min-width: 320px) and (max-width: 480px) {

    .best_practises .table {
        width: 700px !important;
    }

    .barLineContainer {
        height: 500px;
        width: 800px;
    }

    .overflow {
        overflow-x: auto;
    }

    .main_content_website .sorting_table tbody tr:hover {
        background-color: var(--ama-color-background-dark-lighter) !important;
    }

    .main_content_website .sorting_table thead tr th {
        background-color: var(--ama-color-background-dark-darker) !important;
    }

    .tabs_section .ama.tabs-container .nav-tabs .nav-link.active,
    .good_bad .ama.tabs-container .nav-tabs .nav-link.active {
        background-color: var(--ama-color-brand-primary) !important;
    }

    .main_dark .barLineContainer {
        background-color: var(--ama-color-background-dark-darker);
    }

    .main_dark .topTen_section .ama.tabs-container .nav-tabs .nav-link.active,
    .main_dark .BarLine_section .ama.tabs-container .nav-tabs .nav-link.active {
        border: 0px !important;
        background-color: var(--ama-color-text-blue) !important;
        color: var(--ama-color-text-gray) !important;
    }
}

/* DARK MODE */
.main_dark .good_bad .ama.tabs-container .nav-tabs,
.main_dark .tabs_section .ama.tabs-container .nav-tabs {
    border-color: var(--ama-color-background-dark-darker) !important;
    background-color: var(--ama-color-background-dark-darker) !important;
}

.main_dark .good_bad .ama.tabs-container .nav-tabs .nav-link,
.main_dark .tabs_section .ama.tabs-container .nav-tabs .nav-link {
    color: var(--ama-color-text-blue) !important;
    background-color: var(--ama-color-background-dark-darker) !important;
}

.main_dark .good_bad .ama.tabs-container .nav-tabs .nav-link.active,
.main_dark .tabs_section .ama.tabs-container .nav-tabs .nav-link.active {
    border-bottom: 5px solid var(--ama-color-text-blue);
}

.main_content_website {
    background-color: var(--ama-color-background-dark-lighter) !important;
    color: var(--ama-color-background-white);
}

.title_container.main_content_website {
    background-color: transparent !important;
}

.title_container.main_content_website h2 a {
    color: var(--ama-color-text-blue);
}

.main_content_website .sorting_table thead tr,
.main_content_website .best_practises .light_tables .sorting_table thead tr {
    background-color: var(--ama-color-background-dark-darker) !important;
}

.main_content_website .best_practises .light_tables .sorting_table thead tr th {
    color: var(--ama-color-background-white) !important;
}

.main_dark .website .sorting_table tbody tr:hover {
    background-color: transparent !important;
}

.main_dark mark {
    color: var(--ama-color-background-white);
}